
<template>
  <div>
    <heads :more="showMore" :msg="title_msg" :states="1"></heads>
    <div class="tabs">
      <van-tabs v-model="active" title-inactive-color="#333333" title-active-color="#ee2e2e">
        <van-tab :title="'未使用(' + unlist.length + ')'">
          <div v-show="show_unlist">
            <div v-for="(item,index) in unlist" :key="index" class="main1">
              <div class="main_ksy">
                <div class="main_ksy_left">
                  <p v-if="parseFloat(item.discount_radio)" class="money">
                    <span class="moneyTxt">{{ item.discount_radio_name.slice(0,item.discount_radio_name.length-1) }}</span>
                    <span style="color: #fff;">折</span>
                  </p>
                  <p v-else class="money">
                    <span class="rmb">￥</span>
                    <span class="moneyTxt">{{ item.money }}</span>
                  </p>
                  <p class="ctext">{{ item.left_name }}</p>
                </div>
                <div class="main_ksy_right">
                  <div class="main_ksy_right_l">
                    <p>{{ item.right_name }}</p>
                  </div>
                  <div class="main_ksy_right_btn">
                    <span v-show="item.type_id != 3 && item.is_send == 1" @click="send_yours(item.coupon_id,item.uid,item.short_url)">送朋友</span>
                    <span @click="use_q(item)">去使用</span>
                  </div>
                </div>
              </div>
              <div class="main1_btm">
                <div class="main1_btm_left">
                  <div v-if="item.expire_hours">
                    <p>·使用有效期至：{{ item.end_time }}</p>
                  </div>
                  <div v-else>
                    <p>·可使用时间：{{ item.start_time }}至{{ item.end_time }}</p>
                  </div>
                  <p>·多张课程专项券{{ item.superposition == 0 ? '不能' : '能' }}叠加使用</p>
                  <p v-show="item.type_show">·仅限{{ item.type_name }}使用</p>
                </div>
              </div>
            </div>
          </div>
          <div v-show="!show_unlist" class="nodatas">
            <img src="@/static/images/my_index/kong.png" alt="kong">
            <p>您还没有可使用的优惠券~</p>
          </div>
        </van-tab>
        <van-tab :title="'已使用(' + usedlist.length + ')'">
          <div v-show="show_usedlist">
            <div v-for="(item,index) in usedlist" :key="index" class="main1">
              <div class="main_ksy1">
                <div class="main_ksy_left1">
                  <p v-if="parseFloat(item.discount_radio)" class="money">
                    <span class="moneyTxt">{{ item.discount_radio_name.slice(0,item.discount_radio_name.length-1) }}</span>
                    <span style="color: #fff;">折</span>
                  </p>
                  <p v-else class="money">
                    <span class="rmb">￥</span>
                    <span class="moneyTxt">{{ item.money }}</span>
                  </p>
                  <p class="ctext1">{{ item.left_name }}</p>
                </div>
                <div class="main_ksy_right">
                  <div class="main_ksy_right_l">
                    <p>{{ item.right_name }}</p>
                  </div>
                  <div class="main1_btm_right_ygq">
                    <span>已使用</span>
                  </div>
                </div>
              </div>
              <div class="main1_btm">
                <div class="main1_btm_left">
                  <div v-if="item.expire_hours">
                    <p>·使用有效期至：{{ item.end_time }}</p>
                  </div>
                  <div v-else>
                    <p>·可使用时间：{{ item.start_time }}至{{ item.end_time }}</p>
                  </div>
                  <p>·多张课程专项券{{ item.superposition == 0 ? '不能' : '能' }}叠加使用</p>
                  <p v-show="item.type_show">·仅限{{ item.type_name }}使用</p>
                </div>
              </div>
            </div>
          </div>
          <div v-show="!show_usedlist" class="nodatas">
            <img src="@/static/images/my_index/kong.png" alt="kong">
            <p>您还没有已使用的优惠券~</p>
          </div>
        </van-tab>
        <van-tab :title="'已过期(' + list.length + ')'">
          <div v-show="show_list">
            <div v-for="(item,index) in list" :key="index" class="main1">
              <div class="main_ksy1">
                <div class="main_ksy_left1">
                  <p v-if="parseFloat(item.discount_radio)" class="money">
                    <span class="moneyTxt">{{ item.discount_radio_name.slice(0,item.discount_radio_name.length-1) }}</span>
                    <span style="color: #fff;">折</span>
                  </p>
                  <p v-else class="money">
                    <span class="rmb">￥</span>
                    <span class="moneyTxt">{{ item.money }}</span>
                  </p>
                  <p class="ctext1">{{ item.left_name }}</p>
                </div>
                <div class="main_ksy_right">
                  <div class="main_ksy_right_l">
                    <p>{{ item.right_name }}</p>
                  </div>
                  <div class="main1_btm_right_ygq">
                    <span>已过期</span>
                  </div>
                </div>
              </div>
              <div class="main1_btm">
                <div class="main1_btm_left">
                  <div v-if="item.expire_hours">
                    <p>·使用有效期至：{{ item.end_time }}</p>
                  </div>
                  <div v-else>
                    <p>·可使用时间：{{ item.start_time }}至{{ item.end_time }}</p>
                  </div>
                  <p>·多张课程专项券{{ item.superposition == 0 ? '不能' : '能' }}叠加使用</p>
                  <p v-show="item.type_show">·仅限{{ item.type_name }}使用</p>
                </div>
              </div>
            </div>
          </div>
          <div v-show="!show_list" class="nodatas">
            <img src="@/static/images/my_index/kong.png" alt="kong">
            <p>您还没有已过期的优惠券~</p>
          </div>
        </van-tab>
      </van-tabs>
    </div>
  </div>
</template>

<script>
import heads from '@/compontens/heads.vue'
import { get_unuse_yhq, get_beOverdue_yhq, get_used_yhq } from '@/api/my_user.js'
import tool from '@/utils/tools.js'

export default {
  components: {
    heads
  },
  data() {
    return {
      title_msg: '我的优惠券',
      active: 0,
      unlist: [],
      list: [],
      usedlist: [],
      isshow: false,
      showMore: true,
      show_unlist: true,
      show_list: true,
      show_usedlist: true,
      unlistObj: {
        page: 1,
        limit: 100
      },
      // 是否是注考帮pro打开的页面：1 ：是
      zkb_pro: this.$route.query.zkb_pro || ''
    }
  },
  mounted() {
    this.zkb_pro = this.$route.query.zkb_pro || ''
    this.unlistObj.zkb_pro = this.zkb_pro
    if (this.zkb_pro == 1) {
      this.showMore = false
    }
    console.log('zkb_pro = ', this.zkb_pro)
    this.get_unuse()
    this.get_used()
    this.get_beOverdue()
  },
  methods: {
    clicks() {
      if (this.isshow === false) {
        this.isshow = true
      } else {
        this.isshow = false
      }
    },
    // 去使用
    use_q(item) {
      const type = item.type_id
      const use_type = item.use_type
      // const url = item.url_use
      // 判断是筑龙还是注考帮
      var url = ''
      if (window.location.href.includes('zk468')) {
        if (item.lesson_id > 0) {
          url = item.url_use || window.location.origin
        } else {
          url = window.location.origin
        }
      } else {
        // 筑龙
        if (item.type_id == '13') {
          // 通用券
          if (item.use_type == '0') {
            // 训练营
            url = window.origin + '/edu?type=2'
          } else {
            url = window.origin + '/edu?type=1'
          }
        } else {
          switch (item.use_type) {
            case 2:
              url = window.origin + '/pay/?product_type=3'
              break
            case 3:
              url = window.origin + '/pay/?product_type=2'
              break
            case 5:
              url = window.origin + `/edu/bookInfo/?book_id=${item.lesson_id}&num=1`
              break
            default:
              url = window.origin + `/edu/d/${item.lesson_id}.html`
              break
          }
        }
      }

      if (item.is_tk_lesson == 1) {
        url = `https://m.zk468.com/kb_timetable?lesson_id=${item.lesson_id}&is_tk_lesson=1`
      }

      // 2023-09-13 改版，根据URL 判断
      if (url && url.length > 0) {
        if (tool.isInApp()) {
          if (tool.isInIosApp()) {
            window.webkit.messageHandlers.vue_openUrl.postMessage(url)
          } else {
            this.$jsbridge.callHandler(
              'vue_openUrl'
              , url
              , function(responseData) {
                // android 返参数接受
              }
            )
          }
        } else {
          window.location.href = url
        }
      } else {
        var index = 1
        if (tool.isInApp()) {
          if (tool.isInIosApp()) {
            window.webkit.messageHandlers.vue_openTabbar.postMessage(index)
          } else {
            this.$jsbridge.callHandler(
              'vue_openTabbar'
              , index
              , function(responseData) {
                // android 返参数接受
              }
            )
          }
        } else {
          url = window.location.origin
          window.location.href = url
        }
      }
    },
    // 送朋友
    send_yours(id, uid, url_get_coupon) {
      // window.location.href = urls
      this.$router.push({
        path: '/index/coupon/receive',
        query: {
          id: id,
          uid: uid,
          url_get_coupon: url_get_coupon
        }
      })
    },
    // 截取日期
    get_newTime(data) {
      var newtimes = data.slice(0, 10)
      return newtimes
    },
    // 未使用优惠券
    get_unuse() {
      get_unuse_yhq(this.unlistObj).then(res => {
        console.log(res)
        this.unlist = res.result.list
        if (this.unlist && this.unlist.length > 0) {
          this.show_unlist = true
          this.unlist.forEach(item => {
            switch (item.type_id) {
              case 1:
                item.type_name = '老用户'
                item.type_show = true
                break
              case 2:
                item.type_name = '社群好友'
                item.type_show = true
                break
              case 10:
                item.type_show = false
                break
              case 11:
                item.type_name = 'V会员'
                item.type_show = true
                break
              case 12:
                item.type_name = 'E会员'
                item.type_show = true
                break
              default:
                item.type_show = false
                break
            }
          })
        } else {
          this.show_unlist = false
        }
      })
    },
    // 已使用
    get_used() {
      get_used_yhq(this.unlistObj).then(res => {
        console.log(res)
        this.usedlist = res.result.list
        if (this.usedlist && this.usedlist.length > 0) {
          this.show_usedlist = true
          this.usedlist.forEach(item => {
            switch (item.type_id) {
              case 1:
                item.type_name = '老用户'
                item.type_show = true
                break
              case 2:
                item.type_name = '社群好友'
                item.type_show = true
                break
              case 10:
                item.type_show = false
                break
              case 11:
                item.type_name = 'V会员'
                item.type_show = true
                break
              case 12:
                item.type_name = 'E会员'
                item.type_show = true
                break
              default:
                item.type_show = false
                break
            }
          })
        } else {
          this.show_usedlist = false
        }
      })
    },
    // 已过期
    get_beOverdue() {
      get_beOverdue_yhq(this.unlistObj).then(res => {
        console.log(res)
        this.list = res.result.list
        if (this.list && this.list.length > 0) {
          this.show_list = true
          this.list.forEach(item => {
            switch (item.type_id) {
              case 1:
                item.type_name = '老用户'
                item.type_show = true
                break
              case 2:
                item.type_name = '社群好友'
                item.type_show = true
                break
              case 10:
                item.type_show = false
                break
              case 11:
                item.type_name = 'V会员'
                item.type_show = true
                break
              case 12:
                item.type_name = 'E会员'
                item.type_show = true
                break
              default:
                item.type_show = false
                break
            }
          })
        } else {
          this.show_list = false
        }
      })
    }
  },
  beforeRouteEnter(to, from, next) {
    window.document.body.style.backgroundColor = '#f7f7f7'
    next()
  },
  beforeRouteLeave(to, from, next) {
    window.document.body.style.backgroundColor = ''
    next()
  }
}
</script>

<style lang="scss" scoped>
.img_h {
  width: 40px;
  height: 40px;
  position: absolute;
  right: 30px;
  top: 44px;
  z-index: 1;
}
.main1_btm_right_ygq span{
  width: 140px;
  height: 52px;
  text-align: center;
  line-height: 56px;
  font-size: 22px;
  margin: 0 0 0 180px;
  display: inline-block;
  border: 1px solid #999;
  border-radius: 30px;
  background: #999;
  color: #fff;
}

.main1_btm_left p {
  padding: 10px 0 0 20px;
  color: #999999;
  letter-spacing: 1px;
}
.main1_btm_left p:first-child {
  padding-top: 20px;
}
.main1_btm_left {
  width: 520px;
  height: 100%;
}
.main1_btm {
  width: 100%;
  // height: 160px;
  background: #fff;
  margin: 0 0 20px 40px;
  border-radius:  0 0 20px 20px;
  padding-bottom: 20px;
}
.main_ksy_right_btn{
  margin: 0 0 0 30px;
  span{
    display: inline-block;
    width: 140px;
    height: 52px;
    text-align: center;
    line-height: 56px;
  }
  span:first-child{
    background: url(https://newoss.zhulong.com/forum/202205/25/10/1653446927415032.png);
    background-size: 100% 100%;
    color: #ee2e2e;
    margin-right: 20px;
  }
  span:last-child{
    background: url(https://newoss.zhulong.com/forum/202205/25/10/1653447274999965.png);
    background-size: 100% 100%;
    color: #fff;
  }
}
.main_ksy_right_l {
  min-height: 122px;
  font-size: 30px;
  margin: 20px 0 0 20px;
  p{
    padding-top: 5px;
    margin-left: 3px;
  }
}
.main_ksy_left {
  width: 200px;
  height: 200px;
  margin: 5px 0 0 5px;
}
.main_ksy_left1 {
  width: 200px;
  height: 200px;
  // background: #bdbdbd;
  border-radius: 20px;
}
.main_ksy_left .money,
.main_ksy_left1 .money {
  width: 100%;
  display: inline-block;
  text-align: center;
  margin-top: 40px;
  .rmb {
    display: inline-block;
    text-align: center;
    color: #fff;
    font-size: 24px;
  }
  .moneyTxt {
    display: inline-block;
    text-align: center;
    color: #fff;
    font-size: 50px;
  }
}
.ctext {
  color: #f5fde3;
  display: block;
  text-align: center;
  padding-top: 20px;
}
.ctext1 {
  color: #fff;
  display: block;
  text-align: center;
  padding-top: 20px;
}
.main_ksy_right {
  width: 494px;
  height: 183px;
}
.main1 {
  width: 674px;
}
.main_ksy {
  display: flex;
  width: 688px;
  height: 220px;
  margin: 20px 0 0 30px;
  background: url(https://newoss.zhulong.com/forum/202205/25/10/1653444141411729.png) no-repeat;
  background-size: 100% 100%;
}
.main_ksy1 {
  display: flex;
  width: 688px;
  height: 220px;
  margin: 20px 0 0 30px;
  border-radius: 20px 20px 0 0 ;
  background: url(https://newoss.zhulong.com/forum/202205/25/7/101407kwsixuoclecfec98.png) no-repeat;
  background-size: 100% 100%;
}
.nodatas {
  margin-top: 250px;
  text-align: center;
  p {
    color: #333;
    font-size: 26px;
    margin-top: 20px;
  }
}
/deep/.van-tabs__nav--line {
  padding-bottom: 0px;
}
/deep/.van-tabs__wrap {
  border-bottom: 1px solid #e5e5e5;
}
/deep/.van-tabs__line {
  height: 4px;
  bottom: 0px;
}
</style>
